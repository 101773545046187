import React, { useState } from "react";

import { Wrapper, Container, WhiteBoxError, Inner } from "../style/grid";
import { PageHeaderError } from "../style/pageHeader";

export const SENTRY_DEBUG_ROUTE = "/debug/sentry";

export const SentryDebugContainer = () => {
  const [errorInput, setErrorInput] = useState("");

  const errorMethod = () => {
    throw new Error(`[SENTRY-DEBUG] Sentry debug error. ERROR: \`${errorInput}\``);
  };

  return (
    <Wrapper>
      <Container>
        <Inner>
          <WhiteBoxError>
            <PageHeaderError>
              <h1>Sentry DEBUG - sekce</h1>

              <div>
                <input
                  name="errorInput"
                  value={errorInput}
                  onChange={(e) => setErrorInput(e.target.value)}
                  placeholder="Error text"
                />
                <button onClick={errorMethod}>Vyvolat error</button>
              </div>
            </PageHeaderError>
          </WhiteBoxError>
        </Inner>
      </Container>
    </Wrapper>
  );
};
