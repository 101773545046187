import React from "react";
import {render} from "react-dom";
import * as serviceWorker from "./serviceWorker";
import Typography from "typography";
import {createBrowserHistory} from "history";
import {Router} from "react-router-dom";
import {ApolloProvider} from '@apollo/react-hooks';
import {ThemeProvider} from "styled-components";
import {ApolloClient} from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from 'apollo-link-context';
import Modal from "react-modal";
import App from "./containers/App";
import ScrollToTop from "./components/ScrollToTop";
import typographyConfig from "./configs/typography";
import theme from "./style/theme";
import extractParamFromUrl from "./helpers/extractParamFromUrl";
import removeParamFromUrl from "./helpers/removeParamFromUrl";
import {getToken, storeToken} from "./utils/tokenStorage";

// Inicializace sentrys
import "./sentry/sentry-init";

const history = createBrowserHistory();
const typography = new Typography(typographyConfig);
typography.toString();
typography.injectStyles();
const link = createUploadLink({ uri: "/graphql" });
const cache = new InMemoryCache();

const authLink = setContext((_, { headers }) => {
    const tokenFromURL = extractParamFromUrl("token", null);

    if(tokenFromURL) {
        storeToken(tokenFromURL)
        history.push(removeParamFromUrl("token"));
    }

    const token = getToken();

    return {
        headers: {
            ...headers,
            "Authorization": `Bearer ${token ? token : ""}`,
        }
    };
});

const client = new ApolloClient({
    cache: cache,
    link: authLink.concat(link)
});

Modal.setAppElement('#root');

render(
    <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
            <Router history={history}>
                <ScrollToTop />
                <App/>
            </Router>
        </ThemeProvider>
    </ApolloProvider>
, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
