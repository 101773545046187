import * as Sentry from "@sentry/react";
import { getSentryEnvDSN } from "./sentry-values";

const sentryDSN = getSentryEnvDSN();

window.__DEBUG__process = process.env;

if (sentryDSN) {
    Sentry.init({
        dsn: sentryDSN,
    });
    window["__DEBUG__sentry_initialized"] = { initialized: true };
}else {
    window["__DEBUG__sentry_initialized"] = {
        initialized: false,
    };
}