

export const getSentryEnvDSN = () => {
    return process.env.REACT_APP_SENTRY_DSN;
}

export const getSentryEnvDebugEnabled = () => {
    return process.env.REACT_APP_ENABLE_SENTRY_DEBUG === "true";
}

export const getSentryDebugEnabled = () => {
    const hasDSN = !!getSentryEnvDSN();
    return hasDSN && getSentryEnvDebugEnabled();
}